import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { FIRM_IMAGE_BASE } from "../../config/env";
import getFirmsInNetwork from "../../apis/getFirmsInNetwork";

const FirmInNetwork = () => {
  const { userDetails } = useAuthContext();
  const user_slug = JSON.parse(userDetails).user_slug;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (user_slug !== undefined) {
      getFavoriteProfiles();
    }
  }, [user_slug]);

  const getFavoriteProfiles = () => {
    Promise.all([getFirmsInNetwork(user_slug)])
      .then(async ([data]) => {
        if (data?.data?.status === 0) {
          setIsLoading(false);
        } else {
          if (data?.data?.result) {
            setData(data?.data?.result);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const displayLoader = () => {
    return (
      <div className="d-flex p-3 justify-content-center align-items-center">
        <div className="spinner-border" role="status" />
      </div>
    );
  };

  const displayEmptyList = () => {
    return (
      <div className="d-flex p-3 justify-content-center align-items-center">
        <span className="text-muted">Nothing to display here!</span>
      </div>
    );
  };

  const displayList = () => {
    return (
      <div className="d-flex flex-column justify-content-center">
        {data.map(function (item, key) {
          return (
            <div className="d-flex p-3" key={"network-" + key}>
              <div className="col-6">
                <div
                  className="firm-logo-sm-custom"
                  alt={item.firm_name}
                  style={{
                    backgroundImage: `url("${
                      FIRM_IMAGE_BASE + item.firm_logo
                    }")`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </div>

              <div className="col-6 d-flex justify-content-flex-end align-items-center">
                <span>{item.profile_count} profiles</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const displayContent = () => {
    if (!data) {
      return displayEmptyList();
    } else {
      return displayList();
    }
  };

  const checkIsLoading = () => {
    if (isLoading) {
      return displayLoader();
    } else {
      return displayContent();
    }
  };

  return (
    <div className="card-custom shadow-lg border-secondary">
      <div className="card-body">
        <div className="d-block mb-4">
          <h5 className="bd-title m-0 fw-bold">My Network</h5>
          <span className="text-secondary fs-6">
            Firms and searchable profiles
          </span>
        </div>
        <div className="card mb-2 rounded bg-light">
          <div className="p-3">
            <div className="d-flex row">
              <div className="col-12">
                <b className="text-dark fw-bold">Firm</b>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-scroll" style={{ height: 193 }}>
          {checkIsLoading()}
        </div>
      </div>
    </div>
  );
};

export default FirmInNetwork;
