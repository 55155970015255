import React, { useEffect, useState } from "react";
import ProfileImageLg from "../../CommonComponent/ProfileImageLg";
import LoadingPageSm from "../../CommonComponent/LoadingPageSm";
import { useAuthContext } from "../../../context/AuthContext";
import changeFavorite from "../../../apis/changeFavorite";
import star from "../../../assets/images/star.png";
import favorite from "../../../assets/images/favorite.png";

const ProfileBrief = (props) => {
  const { userDetails } = useAuthContext();
  const user_id = JSON.parse(userDetails).user_id;
  const user_primary_role = JSON.parse(userDetails).user_primary_role;

  const { displayView, profileDetails, contactDetails } = props;
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isFav, setIsFav] = useState(0);

  useEffect(() => {
    setIsProfileLoading(false);

    if (profileDetails) {
      if (profileDetails.isFav) {
        setIsFav(profileDetails.isFav);
      }
    }
  }, [profileDetails, contactDetails]);

  const updateFavorite = (status) => {
    const resource_id = profileDetails.user_id;

    Promise.all([changeFavorite(user_id, resource_id)])
      .then(async ([data]) => {
        setIsFav(status);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const displayProfileName = () => {
    if (
      profileDetails.profile_name !== null &&
      profileDetails.profile_name !== ""
    ) {
      return profileDetails.profile_name;
    } else {
      return <span className="text-muted">No name</span>;
    }
  };

  const displayProfileRole = () => {
    if (
      profileDetails.user_profile_role !== null &&
      profileDetails.user_profile_role !== ""
    ) {
      return profileDetails.user_profile_role;
    } else {
      return <span className="text-muted">Profile role not available!</span>;
    }
  };

  const displayLocation = () => {
    if (
      contactDetails.user_address_city_id > 0 ||
      contactDetails.user_address_provience_id > 0 ||
      contactDetails.user_address_country_id > 0
    ) {
      return (
        <span className="text-center">
          {contactDetails.city_name !== null ? contactDetails.city_name : ""}

          {contactDetails.city_name !== null &&
          (contactDetails.state_name !== null ||
            contactDetails.country_name !== null)
            ? ", "
            : ""}

          {contactDetails.state_name !== null ? contactDetails.state_name : ""}

          {contactDetails.state_name !== null &&
          contactDetails.country_name !== null
            ? ", "
            : ""}

          {contactDetails.country_name !== null
            ? contactDetails.country_name
            : ""}
        </span>
      );
    } else {
      return (
        <span className="fw-bold-custom text-muted-custom">
          Location not available!
        </span>
      );
    }
  };

  const displayBlockContent = () => {
    if (isProfileLoading) {
      return <LoadingPageSm />;
    } else {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="profile-img-lg-holder">
            <div className="profile-img-lg">
              <ProfileImageLg
                imgSrc={
                  displayView === "default"
                    ? profileDetails.profile_image_path
                    : "assets/uploads/profile/default/default.png"
                }
              />
            </div>
          </div>

          <div className="d-flex flex-column mb-3 justify-content-center align-items-center">
            <div className="profile-name-lg-holder">
              {displayView === "default" ? (
                <span className="h5 fw-bold-custom">
                  {displayProfileName()}
                </span>
              ) : null}
            </div>

            <div className="role-lg-holder">
              <span className="text-muted fw-bold text-center">
                {displayProfileRole()}
              </span>
            </div>

            <div className="address-lg-holder">{displayLocation()}</div>
          </div>
        </div>
      );
    }
  };

  const displayFavotite = () => {
    return (
      <div className="profile-favorite-holder">
        {isFav === 1 ? (
          <img
            className="img-fluid"
            onClick={() => updateFavorite(2)}
            src={star}
            alt={"img"}
          />
        ) : (
          <img
            className="img-fluid"
            onClick={() => updateFavorite(1)}
            src={favorite}
            alt={"img"}
          />
        )}
      </div>
    );
  };

  return (
    <div className="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 top-card-section">
      <div className="card-custom h-100 d-flex flex-column align-items-center position-relative">
        {user_primary_role !== "3" ? displayFavotite() : null}
        <div className="card-body row w-100">{displayBlockContent()}</div>
      </div>
    </div>
  );
};

export default ProfileBrief;
