import React, { useEffect, useState } from "react";
import recentSearches from "../../apis/recentSearches";
import { useAuthContext } from "../../context/AuthContext";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const RecentSearches = () => {
  const { userDetails } = useAuthContext();
  const user_slug = JSON.parse(userDetails).user_slug;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (user_slug !== undefined) {
      getRecentSearches();
    }
  }, [user_slug]);

  const getRecentSearches = () => {
    Promise.all([recentSearches(user_slug)])
      .then(async ([data]) => {
        if (data?.data?.status === 1) {
          setIsLoading(false);
          setData(data?.data?.result);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const displayLoader = () => {
    return (
      <div className="d-flex p-3 justify-content-center align-items-center">
        <div className="spinner-border" role="status" />
      </div>
    );
  };

  const displayEmptyList = () => {
    return (
      <div className="d-flex p-3 justify-content-center align-items-center">
        <span className="text-muted">Nothing to display here!</span>
      </div>
    );
  };

  const displayKeyword = (keyword) => {
    if (keyword !== null && keyword !== "") {
      return <span className="text-dark">{keyword}</span>;
    } else {
      return <span className="text-secondary">Not specified</span>;
    }
  };

  const displayList = () => {
    return (
      <>
        <div className="card mb-2 rounded bg-light">
          <div className="p-3">
            <div className="d-flex row">
              <div className="col-5">
                <b className="text-dark fw-bold">Keyword</b>
              </div>
              <div className="col-4">
                <b className="text-dark fw-bold">Location</b>
              </div>
              <div className="col-3 d-flex justify-content-center align-items-center">
                <b className="text-dark fw-bold">Action</b>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-scroll" style={{ height: 193 }}>
          {data.map(function (item) {
            return (
              <div className="card mb-2 rounded bg-light" key={item.user_slug}>
                <div className="p-3">
                  <div className="d-flex row">
                    <div className="col-5">
                      <div className="d-block">
                        {displayKeyword(item.keyword)}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-block">
                        {displayKeyword(item.location)}
                      </div>
                    </div>
                    <div className="col-3 d-flex justify-content-center align-items-center">
                      <Link
                        to={`/search?k=${item.keyword}&l=${item.location}&a=`}
                      >
                        <Button variant="primary" size="sm">
                          Search
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const displayContent = () => {
    if (!data) {
      return displayEmptyList();
    } else {
      return displayList();
    }
  };

  const checkIsLoading = () => {
    if (isLoading) {
      return displayLoader();
    } else {
      return displayContent();
    }
  };

  return (
    <div className="card-custom shadow-lg border-secondary">
      <div className="card-body">
        <div className="d-block mb-4">
          <h5 className="bd-title m-0 fw-bold">Recent Searches</h5>
          <span className="text-secondary fs-6">Last 10 search criteria</span>
        </div>
        <div className="d-block">{checkIsLoading()}</div>
      </div>
    </div>
  );
};

export default RecentSearches;
